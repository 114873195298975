import { initAccordion } from './components/initAccordion';
import { initScrollLocker } from './assets/scrollLocker';
import { initAmplitude } from './components/initAmplitude';
import { initForms } from './components/initForms';
import { initHashEmail } from './components/initHashEmail';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initHashMessage } from './components/initHashMessage';

window.addEventListener('DOMContentLoaded', () => {
    initScrollLocker();
    initAccordion();
    initLazyLoadFonts();
    initAmplitude();
    initForms();
    initHashEmail();
    initHashMessage();
    initScrollToAnchor();
});
