import JustValidate from 'just-validate/dist/js/just-validate.min';
import { isReCaptchaValid } from './ReCaptchaActions';

export const initContactForm = () => {
    const formSelector = '[data-contact-us-form]';
    const form = document.querySelector(`${formSelector}`);
    if (!form) return;

    const formAction = new window.JustValidate(`${formSelector}`, {
        rules: {
            email: {
                required: true,
                email: true,
                strength: {
                    custom: '^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,4}$',
                },
            },
            subject: {
                required: true,
            },
            text: {
                required: true,
            },
        },

        submitHandler: function (form, values, ajax) {
            if (!isReCaptchaValid()) {
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    resetForm(form);
                    showMessageSuccessesSend();
                },
                error: function (response) {
                    console.log(response);
                    showMessageErrorSend();
                },
            });
        },
    });

    function resetForm(form) {
        grecaptcha.reset();
        form.reset();
    }

    function showMessageSuccessesSend() {
        const message = document.querySelector('[data-successfully-sent]');
        message.classList.add('is-show');

        setTimeout(() => {
            message.classList.remove('is-show');
        }, 5000);
    }

    function showMessageErrorSend() {
        const message = document.querySelector('[data-error-sent]');
        message.classList.add('is-show');

        setTimeout(() => {
            message.classList.remove('is-show');
        }, 5000);
    }
};
